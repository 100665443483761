import React, { useEffect, Suspense, lazy } from "react";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useLocation,
} from "react-router-dom";

import Header from "./components/header";
/* eslint-disable import/first */
const LoginPage = lazy(() => import("./pages/login"));
const ManageUserProgress = lazy(() => import("./pages/manageuserprogress"));
const ForgetpswdPage = lazy(() => import("./pages/forgetpswd"));
const ResetpswdPage = lazy(() => import("./pages/resetpswd"));
const NotfoundPage = lazy(() => import("./pages/notfound"));
const MealTracker = lazy(() => import("./pages/mealtracker"));
const UserChat = lazy(() => import("./pages/userchat"));
const PrivateRoute = lazy(() => import("./Library/privateRoute"));
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  subscribePusherChannel,
  unSubscribePusherChannel,
} from "./Library/pusherSubscriptionManager";
// import LoginPage from "./pages/login";
// import ForgetpswdPage from "./pages/forgetpswd";
// import ResetpswdPage from "./pages/resetpswd";
// import NotfoundPage from "./pages/notfound";
// import ManageUserProgress from "./pages/manageuserprogress";
// import MealTracker from "./pages/mealtracker";
// import UserChat from "./pages/userchat";
// import PrivateRoute from "./Library/privateRoute";

export default function App() {
  useEffect(() => {
    subscribePusherChannel();
    return () => {
      unSubscribePusherChannel();
    };
  }, []);
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div>
              <Skeleton count={20} />
            </div>
          }
        >
          <Header />
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route
              exact
              path="/forgot-password"
              component={withRouter(ForgetpswdPage)}
            />
            <Route
              exact
              path="/reset-password/:token"
              component={withRouter(ResetpswdPage)}
            />
            <PrivateRoute
              exact
              path="/manage-user-progress"
              component={withRouter(ManageUserProgress)}
            />
            <PrivateRoute
              exact
              path="/user-chat/"
              component={withRouter(UserChat)}
            />
            <Route path="*" component={NotfoundPage} />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
}
