import React, { useState, useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import logo from "../assets/logo.svg";


import { getMethod, tokenError } from "../Library";

import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faHome } from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "./ChangePassword";

export default function Header() {
  const [userName, setUserName] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [userID, setUserID] = useState();

  const [modalShow, setModalShow] = useState(false);

  const location = useLocation();

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const logoutFn = async () => {
    const data = {
      url: "logout",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      localStorage.clear();
      window.location.href = "/";
    } else if (newData.status === false) {
      tokenError(newData.error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("ZrPQW2saO_token")) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }

    // set user name
    let obj = JSON.parse(localStorage.getItem("user"));
    if (obj) {
      setUserName(obj.first_name);
      setUserID(obj.id);
    }
  }, [location.pathname]);

  return (
    <>
      <header className="main bg_2">
        <div className="col-md-4">
          <Link to="/">
            <img src={logo} className="img-fluid logo" alt="logo" />
          </Link>
        </div>
        <div className="col-md-4">
          <h3 className="text-center m-0 font-weight-bold text-cus">
            NWS - Nutritionist Panel
          </h3>
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-end">
          {loggedIn ? (
            <>
              <Link to="/">
                <FontAwesomeIcon className="f-30 mr-3" icon={faHome} />
              </Link>
              {/* <Notification id={userID} /> */}
              <FontAwesomeIcon className="f-30" icon={faUserCircle} />
              <Dropdown className="userNameBox d-inline-block">
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-user"
                  className="text-capitalize px-1 py-3"
                >
                  {userName ? userName : "User"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="min-14">
                  <ul>
                    <li>
                      <a
                        href="javascript:void(0)"
                        className="userName text-decoration-none"
                        onClick={(e) => setModalShow(true)}
                      >
                        Change Password
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        className="userName text-decoration-none"
                        onClick={logoutFn}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : null}
        </div>
      </header>
      <ChangePassword
        showModal={modalShow}
        handleCloseModal={handleCloseModal}
      />
      <div id="pageLoader" className="">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </>
  );
}
