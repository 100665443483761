import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/icons/pe-icon-7-stroke.css";
import "./assets/fonts/fonts.css";
import "./index.css";
import ErrorBoundary from "./components/ErrorBoundary";

// redux
// import { store } from "./store/config";
// import { Provider } from "react-redux";

// console.log(store.getState());

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
    <ErrorBoundary>
    <App />
    </ErrorBoundary>

    {/* </Provider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
