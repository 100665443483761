import Pusher from "pusher-js";

import { pusherKey } from "./configs";

// Pusher.logToConsole = true;

const pusher = new Pusher(pusherKey, {
  cluster: "ap2",
  encrypted: true,
});

const id = localStorage.getItem("user");
const UserId = id ? JSON.parse(id).id : "";
const channelName = UserId ? `ch-${UserId}` : "";
var channel = "";

export function subscribePusherChannel() {
  if (channelName) {
    channel = pusher.subscribe(channelName);
    channel.bind("pusher:subscription_error", (error) => {
      var { status } = error;
      console.log("subscription failed",error)
    });
    channel.bind('pusher:subscription_succeeded', function() {
      console.log('Subscription succeeded!');
    });
  }
}

export function unSubscribePusherChannel() {
  if (channelName) pusher.unsubscribe(channelName);
}

export function bindPusherEvent(eventName, callback, chanelName) {
  if (channel) {
    channel.bind(eventName, callback);
  } else {
    channel = pusher.subscribe(chanelName);
    channel.bind(eventName, callback);
  }
}

export function unbindPusherEvent(eventName) {
    channel.unbind(eventName);
}
