import React from "react";
import { localUrl } from "./configs";
import jQuery from "jquery";

export const CheckToken = () => {
  var token = localStorage.getItem("ZrPQW2saO_token");
  if (token) {
    return true;
  }
  return false;
};

const validateStatusCode = async (response) => {
  if (response.status === 200) {
    isNotLoading();
    return response.json();
  } else {
    localStorage.clear();
    const errObj = await response.json();
    alert(errObj.error);
    window.location.href = "/";
  }
};

// api calls

export const tableDefault = 10;
export const Loader = () => {
  document.getElementById("pageLoader").classList.add("d-block");
};
export const isLoading = () => {
  document.getElementById("pageLoader").classList.add("d-block");
};
export const isNotLoading = () => {
  if (
    document.querySelector("#pageLoader") &&
    document.querySelector("#pageLoader").classList.contains("d-block")
  ) {
    document.querySelector("#pageLoader").classList.remove("d-block");
  }
};

// obj copy functions
export const copyObjs = (obj) =>
  obj
    ? Object.prototype.toString.call(obj) === "[object Object]"
      ? jQuery.extend(true, {}, obj)
      : JSON.parse(JSON.stringify(obj))
    : obj;

export const postMethodNoLoad = async (data) => {
  const id = localStorage.getItem("ZrPQW2saO_token");
  const token = id ? id : "";
  try {
    const response = await fetch(localUrl + data.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data.body),
    });
    return validateStatusCode(response)
  } catch (err) {
    tokenError(err);
    // console.log("status ", err);
    return err;
  }
};

export const postMethod = async (data) => {
  const id = localStorage.getItem("ZrPQW2saO_token");
  // console.log("token", id);
  const token = id ? id : "";
  isLoading();
  // console.log("ApiPost fetch before", data);
  try {
    const response = await fetch(localUrl + data.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data.body),
    });
    return validateStatusCode(response);
  } catch (err) {
    isNotLoading();
    tokenError(err);
    // console.log("err-status ", err);
    return err;
  }
};

// let errr = true;

export const tokenError = (err) => {
  if (err) {
    // localStorage.clear();
    // window.alert(`${err} Please try again.`);
    // window.location.href = "/manage-user-progress";
    // console.log(err);
    // errr = false;
  }
};

export const postMethodFormData = async (data) => {
  const id = localStorage.getItem("ZrPQW2saO_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiPost fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      body: data.body,
    });
    isNotLoading();
    return validateStatusCode(response)
  } catch (err) {
    isNotLoading();
    tokenError(err);
    // console.log("status ", err);
    return err;
  }
};

export const putMethod = async (data) => {
  const id = localStorage.getItem("ZrPQW2saO_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiPut fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data.body),
    });
    isNotLoading();
    return validateStatusCode(response)
  } catch (err) {
    isNotLoading();
    tokenError(err);
    // console.log("status ", err);
    return err;
  }
};

export const deleteMethod = async (data) => {
  const id = localStorage.getItem("ZrPQW2saO_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiDelete fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "Delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    });
    isNotLoading();
    return validateStatusCode(response)
  } catch (err) {
    tokenError(err);
    return err;
  }
};

export const getMethod = async (data) => {
  const id = localStorage.getItem("ZrPQW2saO_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiGt fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    });
    isNotLoading();
    return validateStatusCode(response)
  } catch (err) {
    isNotLoading();
    tokenError(err);
    // console.log("status ", err);
    return err;
  }
};

export const getTableDataNoLoad = async (url) => {
  const data = {
    url: url,
    body: {
      length: tableDefault,
      start: 0,
      draw: tableDefault,
    },
  };
  const newData = await postMethodNoLoad(data);
  if (newData.status == 1 && newData.data) {
    return newData.data;
  } else if (newData.status === false) {
    tokenError(newData.error);
  }
};

export const getTableData = async (url) => {  // this function needs to be written with postMethod api 
  isLoading();
  const data = {
    url: url,
    body: {
      length: tableDefault,
      start: 0,
      draw: tableDefault,
    },
  };
  const newData = await postMethod(data);
  if (newData.status == 1 && newData.data) {
    isNotLoading();
    return newData.data;
  } else if (newData.status === false) {
    tokenError(newData.error);
  }
};

export const getTableDatawithFilter = async (url, name, status) => {    // this function needs to be written with postMethod api 
  let obj = {
    length: tableDefault,
    start: 0,
    draw: tableDefault,
  };
  if (name) {
    obj.nutritionist_first_name = name;
  }
  if (status) {
    obj.nutritionist_status = status;
  }
  const data = {
    url: url,
    body: obj,
  };
  const newData = await postMethod(data);
  // console.log(newData);
  if (newData.status == 1 && newData.data) {
    return newData.data;
  } else if (newData.status === false) {
    tokenError(newData.error);
  }
};

export const copyObj = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

// required fields
export const validateInputs = (obj) => {
  const items = Object.keys(obj);
  const errors = {};
  items.map((key) => {
    const value = obj[key];
    if (!value) {
      errors[key] = key + " is required";
    }
  });
  return errors;
};

export const findErrors = (obj) => {
  const items = Object.keys(obj);
  const errors = [];
  items.map((key) => {
    const value = obj[key];
    if (!value) {
      errors.push(key);
    }
  });
  // console.log("errors", errors);
  if (errors.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
