// live
export const localUrl = "https://api.nourishwithsim.org/api/";

// dev
// export const localUrl = "https://nwsapi.underdev.in/api/";

// test
// export const localUrl = "https://testnwsapi.underdev.in/api/";

// pusher key  dev
// export const pusherKey = "8d1fbd9110bccb46f138";

// pusher key test 
// export const pusherKey = "8d1fbd9110bccb46f138";


// pusher key live
export const pusherKey = "a00ba68339c8d955e76f";
